import { getApiClient } from "../../scripts/topaz-client";

const state = {
  paymentCurrencyCode: "EUR",
  currencies: [],
};

const getters = {
  getBookingCurrency: (state, getters, rootState) => {
    return state.currencies.filter(function (currency) {
      return (
        currency.realCurrencyCode ==
        rootState.booking.bookingInfo.sellCurrency.substring(0, 3)
      );
    })[0];
  },
  getPaymentCurrency: (state) => {
    return state.currencies.filter(function (currency) {
      return currency.realCurrencyCode == state.paymentCurrencyCode;
    })[0];
  },
  getCurrenciesLoaded: (state) => {
    return state.currencies.length > 0;
  },
};

const mutations = {
  setCurrencies(state, payload) {
    state.currencies = payload;
  },
  setPaymentCurrencyCode(state, payload) {
    state.paymentCurrencyCode = payload;
  },
};

const actions = {
  async getCurrencies({ commit }) {
    const client = getApiClient();
    const bankCode = process.env.VUE_APP_LIVE_RATES_BANK_CODE;

    if (bankCode != "") {
      const allowedCurrencyCodes =
        process.env.VUE_APP_LIVE_RATES_CURRENCIES.split(",");

      const searchParams = new URLSearchParams();
      allowedCurrencyCodes.forEach((currencyCode) => {
        searchParams.append("currencyCodes", currencyCode);
      });

      const requestUrl = `/lookup/currencies/live-rates/${bankCode}?${searchParams.toString()}`;
      const res = await client.get(requestUrl);
      const currencies = res.data.map(function (currency) {
        return {
          symbol: currency.shortName,
          currencyCode: currency.currencyCode,
          realCurrencyCode: currency.realCurrencyCode,
          name: currency.name,
          exchangeRate: currency.exchangeRate,
        };
      });

      commit("setCurrencies", currencies);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
