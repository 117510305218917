<template>
  <button
    :class="classNameOrDefault"
    @click="$emit('click', $event.target.value)"
    :disabled="disabled || busy"
  >
    <span
      v-show="busy"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    {{ caption }}
  </button>
</template>

<script>
export default {
  props: {
    className: String,
    caption: {
      Type: String,
      Required: true,
    },
    disabled: {
      Type: Boolean,
      Required: false,
      Default: false,
    },
    busy: {
      Type: Boolean,
      Required: false,
      Default: false,
    },
  },
  computed: {
    classNameOrDefault() {
      if (this.className == undefined) {
        return "btn btn-primary";
      }

      return this.className;
    },
  },
};
</script>
