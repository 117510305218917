<template>
  <div :class="classNameOrDefault" role="alert">
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    className: {
      Type: String,
      Required: false,
      Default: "",
    },
    value: {
      Type: String,
      Required: true,
    },
  },
  computed: {
    classNameOrDefault() {
      if (this.className == undefined) {
        return "alert alert-danger";
      } else {
        return this.className;
      }
    },
  },
};
</script>
