export function convertC(amount, exchangeRate) {
  if (amount == 0.0) {
    return amount;
  }

  return (amount / exchangeRate).toFixed(2);
}

export function formatC(symbol, value) {
  const numValue = new Number(value).toFixed(2);
  if (symbol.includes("~")) {
    return symbol.replace("~", numValue);
  }

  return `${symbol}${numValue}`;
}

export function formatCConversion(
  sourceCurrency,
  sourceAmount,
  targetCurrency,
  targetAmount
) {
  if (targetCurrency.currencyCode == sourceCurrency.currencyCode) {
    return formatC(targetCurrency.symbol, targetAmount);
  }

  return `${formatC(targetCurrency.symbol, targetAmount)} (${formatC(
    sourceCurrency.symbol,
    sourceAmount
  )})`;
}
