import { getApiClient } from "@/scripts/topaz-client";
import { formatCConversion } from "@/scripts/currency.js";

const state = {
  stripeSecret: "",
};

const getters = {
    getPaymentDescription: (state, getters, rootState) => {
      const printedAmount = formatCConversion(
        getters.getPaymentCurrency,
        rootState.payment.paymentAmount,
        getters.getBookingCurrency,
        getters.getBookingPaymentAmount
      );

      return `${printedAmount} paid against ${getters.getBookingRef}`;
    },
    getPaymentIntentRequestPostData: (state, getters, rootState) => {
      return {
        bookingId: getters.getBookingId,
        groupId: getters.getGroupId,
        clientId: getters.getClientId,
        clientType: getters.getClientType,
        clientEmail: rootState.email.receiptEmail,
        currency: getters.getPaymentCurrency.currencyCode,
        amount: rootState.payment.paymentAmount,
        exchangeRate: getters.getPaymentCurrency.exchangeRate,
        description: getters.getPaymentDescription,
      };
    },
  },
  mutations = {
    setStripeSecret(state, payload) {
      state.stripeSecret = payload;
    },
  };

const actions = {
  async createPaymentIntent({ getters, commit, dispatch }) {
    const client = getApiClient();
    const data = getters.getPaymentIntentRequestPostData;

    try {
      const response = await client.post("/stripe/payment-intents", data);
      commit("setStripeSecret", response.data);
    } catch (error) {
      dispatch("resetStripeSecret");
    }
  },
  resetStripeSecret({ commit }) {
    commit("setStripeSecret", "");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
