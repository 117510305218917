const state = {
  receiptEmail: null,
};

const getters = {
  getHasUnchangedEmail(state, getters, rootState) {
    if (
      state.receiptEmail != "" &&
      rootState.booking.bookingInfo.clientEmail != "" &&
      rootState.booking.bookingInfo.clientEmail == state.receiptEmail
    ) {
      return true;
    }

    return false;
  },
};

const mutations = {
  setReceiptEmail(state, payload) {
    state.receiptEmail = payload;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  mutations,
};
