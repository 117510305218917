<template>
  <div>
    <div class="card">
      <h4 class="card-header">Confirm Email</h4>
      <p class="card-body lead">
        {{ leadMessage }}
      </p>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <EmailInput
              className="mb-3"
              id="receiptEmail"
              label="Email Address"
              v-model="receiptEmail"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <router-link to="/payment-amount">
              <Button class="btn btn-primary" :caption="buttonCaption" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import EmailInput from "@/components/EmailInput.vue";

export default {
  components: {
    Button,
    EmailInput,
  },
  computed: {
    receiptEmail: {
      get() {
        return this.$store.state.email.receiptEmail;
      },
      set(newValue) {
        return this.$store.commit("setReceiptEmail", newValue);
      },
    },
    emailUnchanged() {
      return this.$store.getters.getHasUnchangedEmail;
    },
    leadMessage() {
      let message =
        "On completion of your payment we'll email you a receipt.  ";
      if (this.emailUnchanged) {
        message += "Please check the email address we hold for you is correct.";
      } else {
        message += "Please enter your email address below";
      }

      return message;
    },
    buttonCaption() {
      if (this.emailUnchanged) {
        return "Confirm Email";
      } else {
        return "Next";
      }
    },
  },
};
</script>
