<template>
  <div>
    <div class="card">
      <h4 class="card-header">Find your Booking</h4>
      <p class="card-body lead">
        To make a secure online payment by debit or credit card, please search
        for your holiday using your booking reference and lead passenger surname
        below
      </p>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <TextInput
              :disabled="isBusy"
              className="mb-3"
              id="bookingRef"
              label="Booking Reference"
              v-model="bookingRef"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <TextInput
              :disabled="isBusy"
              className="mb-3"
              id="leadPassengerSurname"
              label="Lead Passenger Surname"
              v-model="leadPassengerSurname"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <Button
              @click="findBooking"
              :disabled="!isFormValid"
              v-if="!hasBooking"
              :busy="isBusy"
              caption="Find Booking"
            />
          </div>
        </div>
        <div v-if="hasBooking" class="row">
          <div class="col-auto mt-2">
            <p><span v-html="foundBookingMessage"></span></p>
          </div>
          <div class="col">
            <router-link to="/confirm-email" v-if="hasBooking">
              <Button class="btn btn-primary d-inline" caption="Yes" />
            </router-link>
          </div>
        </div>
        <Alert
          v-show="errorMessage != ''"
          className="alert alert-danger mt-2"
          :value="errorMessage"
        />
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert.vue";
import Button from "@/components/Button.vue";
import TextInput from "@/components/TextInput.vue";

export default {
  components: {
    Alert,
    Button,
    TextInput,
  },
  data() {
    return {
      bookingRef: "",
      leadPassengerSurname: "",
      isBusy: false,
      isValid: false,
      errorMessage: "",
    };
  },
  computed: {
    bookingInfo() {
      return this.$store.state.booking.bookingInfo;
    },
    hasBooking() {
      return this.$store.getters.getHasBookingInfo;
    },
    seperatorIndex() {
      return this.bookingRef.indexOf("-");
    },
    hasSeperator() {
      return this.seperatorIndex > -1;
    },
    bookingId() {
      if (this.hasSeperator) {
        return parseInt(this.bookingRef.substring(0, this.seperatorIndex));
      } else {
        return parseInt(this.bookingRef);
      }
    },
    groupId() {
      if (this.hasSeperator) {
        return parseInt(this.bookingRef.substring(this.seperatorIndex + 1));
      } else {
        return 0;
      }
    },
    departureDate() {
      return new Date(this.bookingInfo.departureDate);
    },
    foundBookingMessage() {
      return `Found booking <mark><strong>${
        this.bookingInfo.bookingTitle
      }</strong></mark> departing <mark><strong>${this.departureDate.toLocaleDateString()}</strong></mark>.  Continue?`;
    },
    bookingRefValid() {
      return isNaN(this.bookingId) == false && isNaN(this.groupId) == false;
    },
    isFormValid() {
      return this.bookingRefValid && this.leadPassengerSurname != "";
    },
  },
  methods: {
    async findBooking() {
      try {
        this.errorMessage = "";
        this.isBusy = true;

        await this.$store.dispatch("findBookingInfo", {
          bookingId: this.bookingId,
          groupId: this.groupId,
          leadPassengerSurname: this.leadPassengerSurname,
        });

        if (this.hasBooking == false) {
          this.errorMessage =
            "Sorry we couldn't find your booking.  Please check your details and try again.";
        }
      } catch {
        this.errorMessage =
          "Cannot contact payment server.  Please try again later.";
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
