<template>
  <div class="d-flex flex-column min-vh-100">
    <div id="app" class="container">
      <div class="row mt-5 justify-content-center">
        <div class="col-auto">
          <img class="img-fluid" :src="logoUrl" />
        </div>
      </div>
      <hr />
      <div class="row mt-4">
        <div class="col">
          <h2>Pay By Card</h2>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <router-view />
        </div>
      </div>
    </div>

    <footer class="container-fluid bg-dark text-light py-5 mt-auto">
      Copyright {{ companyName }}
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    companyName() {
      return process.env.VUE_APP_COMPANY;
    },
    logoUrl() {
      return require(`./styles/themes/${process.env.VUE_APP_THEME}/logo.jpg`);
    },
  },
};
</script>
