<template>
  <div>
    <div class="card">
      <h4 class="card-header">Secure Payment - {{ amountToPay }}</h4>
      <div v-if="!ready" class="card-body">
        <div>
          <strong>Loading secure payment form...</strong>
          <div class="ml-5 spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form v-show="ready" @submit="submitPayment($event)">
          <fieldset :disabled="stripeSubmitted">
            <div class="row my-2">
              <div class="col">
                <label for="nameoncard" class="card-test"> Name on card </label>
                <input
                  class="form-control"
                  :class="{ invalid: $v.nameOnCard.$error }"
                  type="text"
                  placeholder="Mr J Smith"
                  @blur="$v.nameOnCard.$touch()"
                  v-model="nameOnCard"
                />
              </div>
              <div class="row my-2">
                <div class="col">
                  <label for="cardelement" class="card-text">
                    Credit or debit card details
                  </label>
                  <div id="cardelement" ref="cardelement"></div>
                </div>
              </div>
              <div v-if="stripeMessage != ''" class="row mt-4">
                <div class="col">
                  <!-- Used to display form errors. -->
                  <div id="card-errors" class="alert alert-warning">
                    <p class="mb-0">{{ stripeMessage }}</p>
                    <p class="mb-0">{{ attemptsMessage }}</p>
                  </div>
                </div>
              </div>
              <div class="row mt-3 justify-content-end">
                <button
                  :disabled="!stripeComplete || $v.nameOnCard.$invalid"
                  @click="submitPayment"
                  class="btn btn-primary"
                >
                  Submit Payment
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import CurrencyMixin from "@/mixins/currency";

let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
let elements = stripe.elements();
let card = null;

export default {
  mixins: [CurrencyMixin],
  data() {
    return {
      nameOnCard: "",
      maxAttempts: 3,
      attempts: 0,
      stripeMessage: "",
      stripeSubmitted: false,
      stripeComplete: false,
    };
  },
  validations: {
    nameOnCard: { required },
  },
  computed: {
    ...mapState({
      stripeSecret: (state) => state.stripe.stripeSecret,
      receiptEmail: (state) => state.payment.receiptEmail,
    }),
    amountToPay() {
      return this.printPaymentCurrency(this.$store.state.payment.paymentAmount);
    },
    attemptsRemaining() {
      return this.maxAttempts - this.attempts;
    },
    attemptsMessage() {
      if (this.attemptsRemaining == 1) {
        return "You have 1 attempt remaining.";
      } else {
        return "You have " + this.attemptsRemaining + " attempts remaining.";
      }
    },
    ready() {
      return this.stripeSecret != "";
    },
    readyToSubmit() {
      return this.nameOnCard != "" && this.stripeComplete;
    },
  },
  methods: {
    ...mapActions(["createPaymentIntent", "resetStripeSecret"]),
    async submitPayment($event) {
      this.stripeSubmitted = true;
      $event.preventDefault();
      let result = await stripe.confirmCardPayment(this.stripeSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: this.nameOnCard,
          },
        },
        receipt_email: this.receiptEmail,
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.stripeMessage = result.error.message;

        // increment the number of attempts
        this.attempts = this.attempts + 1;
        if (this.attempts == this.maxAttempts) {
          // assume this won't end well - move to complete
          this.$store.state.booking.paymentFailed = true;
          this.$router.push("thank-you");
        } else {
          this.stripeSubmitted = false;
        }
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          // Show a stripeSuccess message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          this.$router.push("thank-you");
        }
      }
    },
  },
  async created() {
    await this.createPaymentIntent();
  },
  mounted() {
    // Set up Stripe.js and Elements to use in checkout form
    card = elements.create("card");
    card.mount(this.$refs.cardelement);

    // add an event listener to feedback stripe errors to the user
    card.addEventListener("change", ({ complete, error }) => {
      if (error) {
        this.stripeMessage = error.message;
      } else {
        this.stripeMessage = "";
      }

      this.stripeComplete = complete;
    });
  },
  beforeDestroy() {
    // remove the stripe secret
    this.resetStripeSecret();

    // remove the stripe element
    if (card) {
      card.destroy();
    }
  },
};
</script>

<style scoped>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
