const msSecond = 1000;
// const msMinute = msSecond * 60;
// const msHour = msMinute * 60;
// const msDay = msHour * 24;

export function addSeconds(date, seconds) {
  let ms = seconds * msSecond;
  var current = new Date(date);

  return new Date(current.getTime() + ms);
}
