import { getApiClient } from "@/scripts/topaz-client";

const state = {
  bookingInfo: null,
};

const getters = {
  getHasBookingInfo(state) {
    return state.bookingInfo != null;
  },
  getBookingId(state) {
    return state.bookingInfo.bookingId;
  },
  getGroupId(state) {
    return state.bookingInfo.groupId;
  },
  getBookingRef(state, getters) {
    if (getters.getGroupId == 0) {
      return `${getters.getBookingId}`;
    }

    return `${getters.getBookingId}/${getters.getGroupId}`;
  },
  getClientId(state) {
    return state.bookingInfo.clientId;
  },
  getClientType(state) {
    return state.bookingInfo.clientType;
  },
};

const mutations = {
  setBookingInfo(state, payload) {
    state.bookingInfo = payload;
  },
};

const actions = {
  async findBookingInfo({ commit }, payload) {
    const client = getApiClient();
    const res = await client.get(
      `/portal/bookings/${payload.bookingId}/${payload.groupId}/payment-info/${payload.leadPassengerSurname}`
    );
    commit("setBookingInfo", res.data);
    commit("setPaymentCurrencyCode", res.data.sellCurrency);
    commit("setReceiptEmail", res.data.clientEmail);
  },
};

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};
