<template>
  <div>
    <div v-if="!ready" class="row">
      <div>
        <strong>Loading currencies... </strong>
        <div class="ml-5 spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-if="ready" class="card">
      <h4 class="card-header">Payment Details</h4>
      <p class="card-body lead">
        {{ leadCaption }}
      </p>
      <div class="card-body">
        <div class="row mb-3">
          <label class="col-sm-6 col-form-label">{{ currencyCaption }}</label>
          <div v-if="canChooseCurrency" class="col-sm-6">
            <SelectCurrency />
          </div>
          <div v-if="!canChooseCurrency" class="col-sm-6 col-form-label">
            {{ getBookingCurrency | print }}
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-sm-6 col-form-label pt-0">
            {{ amountCaption }}
          </label>
          <div class="col-sm-6">
            <div v-if="allowDeposit" class="row">
              <div class="col-4">Deposit</div>
              <div class="col-8">
                <RadioButton id="deposit" value="deposit" v-model="amountType">
                  {{ printWithConversion(bookingDeposit, paymentDeposit) }}
                </RadioButton>
              </div>
            </div>

            <div v-if="allowDeposit" class="row">
              <div class="col-4">Balance</div>
              <div class="col-8">
                <RadioButton
                  id="balance"
                  value="balance"
                  :disabled="bookingInfo.balancePaid"
                  v-model="amountType"
                >
                  {{ printWithConversion(bookingBalance, paymentBalance) }}
                </RadioButton>
              </div>
            </div>

            <div v-if="!allowDeposit" class="row">
              <div class="col">
                {{ printWithConversion(bookingBalance, paymentBalance) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="showTerms" class="row my-3 flex-row-reverse">
          <div class="offset-sm-6 col-sm-6">
            <CheckBox id="terms-and-conditions" v-model="termsAccepted">
              I accept the {{ companyName }}
              <a :href="termsUrl" target="_blank">terms and conditions</a>
            </CheckBox>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            <Button
              @click="next()"
              :disabled="buttonDisabled"
              class="w-50"
              :caption="buttonCaption"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CurrencyMixin from "@/mixins/currency";
import Button from "@/components/Button.vue";
import CheckBox from "@/components/CheckBox.vue";
import SelectCurrency from "@/components/SelectCurrency.vue";
import RadioButton from "@/components/RadioButton.vue";

export default {
  mixins: [CurrencyMixin],
  components: {
    Button,
    SelectCurrency,
    RadioButton,
    CheckBox,
  },
  data() {
    return {
      amountType: "balance",
      termsAccepted: false,
    };
  },
  computed: {
    ...mapState({
      bookingInfo: (state) => state.booking.bookingInfo,
    }),
    ...mapGetters([
      "getBookingRef",
      "getHasBookingInfo",
      "getPaymentCurrency",
      "getBookingCurrency",
      "getCurrenciesLoaded",
    ]),
    companyName() {
      return process.env.VUE_APP_COMPANY;
    },
    canChooseCurrency() {
      return process.env.VUE_APP_LIVE_RATES == "true";
    },
    currencyCaption() {
      return this.canChooseCurrency ? "Select Currency" : "Currency";
    },
    ready() {
      return this.getHasBookingInfo && this.getCurrenciesLoaded;
    },
    leadCaption() {
      let message = "Please select your preferred currency";
      if (!this.allowDeposit) {
        message += " and the amount you would like to pay";
      }

      message +=
        ". If you choose a currency other than Euro then we will convert the Euro amount at today's exchange rate.";

      return message;
    },
    bookingDeposit() {
      return this.bookingInfo.depositRemaining;
    },
    paymentDeposit() {
      return this.convertToPaymentCurrency(this.bookingDeposit);
    },
    bookingBalance() {
      return this.bookingInfo.balanceRemaining;
    },
    paymentBalance() {
      return this.convertToPaymentCurrency(this.bookingBalance);
    },
    paymentAmount() {
      return this.amountType == "deposit"
        ? this.paymentDeposit
        : this.paymentBalance;
    },
    bookingAmount() {
      return this.amountType == "deposit"
        ? this.bookingDeposit
        : this.bookingBalance;
    },
    allowDeposit() {
      if (this.bookingInfo.balanceDue || this.bookingInfo.depositPaid) {
        return false;
      }
      return true;
    },
    amountCaption() {
      return !this.allowDeposit ? "Payment Amount" : "Select Amount";
    },
    buttonCaption() {
      return `Pay ${this.printPaymentCurrency(this.paymentAmount)}`;
    },
    termsUrl() {
      return process.env.VUE_APP_TERMS_AND_CONDITIONS_URL;
    },
    showTerms() {
      return this.termsUrl != "";
    },
    buttonDisabled() {
      return this.showTerms && !this.termsAccepted;
    },
  },
  methods: {
    next() {
      this.$store.commit("setPaymentAmount", this.paymentAmount);
      this.$router.push("secure-payment");
    },
  },
  async created() {
    await this.$store.dispatch("getCurrencies");
  },
};
</script>
