<template>
  <div class="form-check">
    <input
      class="form-check-input"
      type="radio"
      :id="id"
      :value="value"
      :checked="shouldBeChecked"
      @change="updateInput"
      :disabled="disabled"
    />
    <label class="form-check-label" :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    id: {
      Type: String,
      Required: true,
    },
    disabled: {
      Type: Boolean,
      Required: false,
      Default: false,
    },
    value: {
      Type: String,
      Required: true,
    },
    modelValue: {
      Type: String,
      Default: "",
    },
  },
  computed: {
    shouldBeChecked() {
      return this.modelValue == this.value;
    },
  },
  methods: {
    updateInput() {
      this.$emit("change", this.value);
    },
  },
};
</script>
