import axios from "axios";
import { addSeconds } from "./date.js";

export function getApiClient() {
  const client = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
  });
  client.interceptors.request.use(
    (config) => {
      return authorize().then((res) => {
        // read our token information from local storage
        /*if (config.method === "get") {
          config.params = config.params || {};
          config.params.bearer_token = res.access_token;
        } else {*/
        config.headers.Authorization = `Bearer ${res.accessToken}`;
        // }
        return Promise.resolve(config);
      });
    },
    (error) => Promise.reject(error)
  );

  return client;
}

export function encodeArrayAsQueryString(array, parameterName) {
  let result = "";

  for (let i = 0; i < array.length; i++) {
    result += `${parameterName}[${i}]=${array[i]}&`;
  }

  return result.substring(0, result.length - 1);
}

function getSessionToken() {
  return {
    accessToken: sessionStorage.getItem("access_token"),
    tokenExpires: new Date(sessionStorage.getItem("token_expires")),
  };
}

function setSessionToken(data) {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("token_expires", new Date(data.expires));
}

function isTokenValid(token) {
  return (
    token.accessToken != null &&
    token.tokenExpires != null &&
    addSeconds(Date.now(), 120) < token.tokenExpires
  );
}

export function authorize() {
  const sessionToken = getSessionToken();

  // authorise if the token is invalid
  if (isTokenValid(sessionToken)) {
    return new Promise((resolve) => {
      resolve({
        accessToken: sessionToken.accessToken,
      });
    });
  } else {
    // authorize if no token available
    const client = axios.create({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    // setup the query parameters
    const params = new URLSearchParams();
    params.append("grant_type", "client_credentials");
    params.append("client_id", process.env.VUE_APP_AUTH_CLIENT_ID);
    params.append("client_secret", process.env.VUE_APP_AUTH_CLIENT_SECRET);

    return client
      .post(process.env.VUE_APP_AUTH_TOKEN_URL, params)
      .then((res) => {
        setSessionToken(res.data);

        return {
          accessToken: res.data.access_token,
        };
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
