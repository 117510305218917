import Vue from "vue";
import Vuex from "vuex";
import EmailModule from "./modules/email";
import BookingModule from "./modules/booking";
import CurrencyModule from "./modules/currency";
import StripeModule from "./modules/stripe";
import PaymentModule from "./modules/payment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    booking: BookingModule,
    currency: CurrencyModule,
    email: EmailModule,
    payment: PaymentModule,
    stripe: StripeModule,
  },
});
