import { mapGetters } from "vuex";
import { formatC, formatCConversion } from "@/scripts/currency.js";

export default {
  computed: {
    ...mapGetters(["getPaymentCurrency", "getBookingCurrency"]),
  },
  methods: {
    convertToPaymentCurrency(amount) {
      const homeAmount = amount / this.getBookingCurrency.exchangeRate;
      return (homeAmount * this.getPaymentCurrency.exchangeRate).toFixed(2);
    },
    printBookingCurrency(amount) {
      return formatC(this.getBookingCurrency.symbol, amount);
    },
    printPaymentCurrency(amount) {
      return formatC(this.getPaymentCurrency.symbol, amount);
    },
    printWithConversion(bookingAmount, paymentAmount) {
      return formatCConversion(
        this.getBookingCurrency,
        bookingAmount,
        this.getPaymentCurrency,
        paymentAmount
      );
    },
  },
  filters: {
    print(currency) {
      return `${currency.realCurrencyCode} (${currency.symbol.replace(
        "~",
        ""
      )}) - ${currency.name}`;
    },
  },
};
