<template>
  <div>
    <div class="card mt-2">
      <h3 class="card-header">Thank you - you are all paid up!</h3>
      <div class="card-body">
        <p class="card-text">
          You have already paid the balance due for
          {{ bookingTitle }} departing on {{ departureDate }}.
        </p>
        <p class="card-text">
          While you look forward to your trip, follow us on
          <a href="www.facebook.com/balticholidays">Facebook</a>,
          <a href="www.instagram.com/balticholidays">Instagram</a> or
          <a href="www.twitter.com/balticholidays">Twitter</a> to keep in touch.
        </p>
        <p class="card-text">
          Thank you for travelling with {{ companyName }}.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      bookingTitle: (state) => state.booking.bookingInfo.bookingTitle,
      departureDate: (state) =>
        new Date(state.booking.bookingInfo.departureDate).toLocaleDateString(),
    }),
    companyName() {
      return process.env.VUE_APP_COMPANY;
    },
  },
};
</script>
