const state = {
  paymentAmount: null,
};

const getters = {
  getHasPaymentAmount(state) {
    return state.paymentAmount != null;
  },
  getBookingPaymentAmount(state, getters) {
    const currency = getters.getPaymentCurrency;
    const bookingCurrency = getters.getBookingCurrency;

    let bookingAmount = state.paymentAmount / currency.exchangeRate;
    bookingAmount = bookingAmount * bookingCurrency.exchangeRate;
    bookingAmount = bookingAmount.toFixed(2);

    return bookingAmount;
  },
};

const mutations = {
  setPaymentAmount(state, payload) {
    state.paymentAmount = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
