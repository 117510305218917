<template>
  <div>
    <div v-if="this.$store.state.booking.paymentFailed" class="card mt-2">
      <h3 class="card-header">
        We are sorry that your payment couldn't be completed.
      </h3>
      <div class="card-body">
        <p class="card-text">
          This is usually due to a small error when entering the information, or
          your card provider has stopped the payment as it is an unusual
          transaction. This will require you to call them and authorise the
          payment.
        </p>
        <p class="card-text">
          Please contact your {{ companyName }} travel advisor and we will be
          happy to assist.
        </p>
        <p class="card-text">We look forward to hearing from you.</p>
      </div>
    </div>
    <div v-if="!this.$store.state.booking.paymentFailed" class="card mt-2">
      <h3 class="card-header">Thank you for your payment</h3>
      <div class="card-body">
        <p class="card-text">
          We have received your payment of {{ amountPaid }}. You will receive a
          receipt for your payment shortly.
        </p>
        <p class="card-text">
          While we finalise your travel plans and you look forward to your trip,
          follow us on <a href="www.facebook.com/balticholidays">Facebook</a>,
          <a href="www.instagram.com/balticholidays">Instagram</a> or
          <a href="www.twitter.com/balticholidays">Twitter</a> to keep in touch.
        </p>
        <p class="card-text">Thank you for travelling with {{ companyName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyMixin from "@/mixins/currency";
import { mapState, mapGetters } from "vuex";

export default {
  mixins: [CurrencyMixin],
  computed: {
    ...mapState({
      bookingInfo: (state) => state.booking.bookingInfo,
    }),
    ...mapGetters(["bookingRef"]),
    companyName() {
      return process.env.VUE_APP_COMPANY;
    },
    amountPaid() {
      return this.printPaymentCurrency(this.$store.state.payment.paymentAmount);
    },
  },
};
</script>
