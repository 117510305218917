<template>
  <div :class="className">
    <label :for="id" class="form-label">{{ label }}</label>
    <input
      :id="id"
      :disabled="disabled"
      class="form-control"
      type="email"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    className: {
      Type: String,
      Required: false,
      Default: "",
    },
    id: {
      Type: String,
      Required: true,
    },
    label: {
      Type: String,
      Required: true,
    },
    disabled: {
      Type: Boolean,
      Required: false,
      Default: false,
    },
    value: {
      Type: String,
      Required: true,
    },
  },
};
</script>
