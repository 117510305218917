<template>
  <select id="select-currency" class="form-select" v-model="currencyCode">
    <option
      v-for="currency in currencies"
      :key="currency.realCurrencyCode"
      :value="currency.realCurrencyCode"
    >
      {{ currency | print }}
    </option>
  </select>
</template>

<script>
import { mapState } from "vuex";
import CurrencyMixin from "../mixins/currency";

export default {
  mixins: [CurrencyMixin],
  computed: {
    ...mapState({
      currencies: (state) => state.currency.currencies,
    }),
    currencyCode: {
      get() {
        return this.$store.state.currency.paymentCurrencyCode;
      },
      set(newValue) {
        return this.$store.commit("setPaymentCurrencyCode", newValue);
      },
    },
  },
};
</script>
