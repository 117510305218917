import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import FindBooking from "../views/FindBooking.vue";
import ReceiptEmail from "../views/ReceiptEmail.vue";
import PaymentAmount from "../views/PaymentAmount.vue";
import SecurePayment from "../views/SecurePayment.vue";
import Thankyou from "../views/Thankyou.vue";
import BalancePaid from "../views/BalancePaid.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "FindBooking",
    component: FindBooking,
  },
  {
    path: "/confirm-email",
    name: "confirm-email",
    component: ReceiptEmail,
  },
  {
    path: "/payment-amount",
    name: "payment-amount",
    component: PaymentAmount,
  },
  {
    path: "/secure-payment",
    name: "secure-payment",
    component: SecurePayment,
  },
  {
    path: "/thank-you",
    name: "thank-you",
    component: Thankyou,
  },
  {
    path: "/balance-paid",
    name: "balance-paid",
    component: BalancePaid,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (store.getters.getHasBookingInfo == false && to.path != "/") {
    // if booking info is null on any page (other than root)
    // then revert back to the root
    next("/");
  } else {
    if (to.path == "/") {
      // if navigating to the root then check the query string for args
      if (
        to.query.bookingId !== undefined &&
        to.query.groupId !== undefined &&
        to.query.leadPassengerSurname !== undefined
      ) {
        await store.dispatch("findBookingInfo", to.query);
        next("/confirm-email");
      } else {
        store.commit("setBookingInfo", null);
        next();
      }
    } else if (from.path == "/" && to.path != "/balance-paid") {
      // if navigating from the root check whether any payment is needed
      // if not direct to /balance-paid
      if (
        store.state.booking.bookingInfo.price <=
        store.state.booking.bookingInfo.received
      ) {
        next("/balance-paid");
      } else {
        next();
      }
    } else if (from.path == "/secure-payment") {
      // if navigating from secure payment then only allow us to move forward to thank you
      if (to.path == "/thank-you") {
        next();
      } else {
        next(false);
      }
    } else if (from.path == "/thank-you" || from.path == "/balance-paid") {
      // if navigating from thank-you or balance-paid reset the booking info and navigate back to the root
      store.commit("setBookingInfo", null);
      next("/");
    } else {
      // everything ok next page
      next();
    }
  }
});

export default router;
